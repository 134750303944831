var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "typeform-container",
      style: { width: _vm.width, height: _vm.height }
    },
    [
      _c("iframe", {
        attrs: {
          width: "100%",
          height: "100%",
          frameborder: "0",
          allow: "camera; microphone; autoplay; encrypted-media;",
          src: _vm.src
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }