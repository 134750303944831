<template>
    <div class="typeform-container" :style="{ width, height }">
        <iframe
            width="100%"
            height="100%"
            frameborder="0"
            allow="camera; microphone; autoplay; encrypted-media;"
            :src="src"
        />
    </div>
</template>

<script>
export default {
    props: {
        src: {
        type: String,
        required: true,
        },
        width: {
        type: String,
        default: '100%',
        },
        height: {
        type: String,
        default: '100%',
        },
    },
    mounted() {
        window.addEventListener('message', this.onSubmitCallback)
    },
    beforeDestroy() {
        window.removeEventListener('message', this.onSubmitCallback)
    },
    methods: {
        onSubmitCallback(e) {
            if (e?.data?.type == 'form-submit') {
                this.$emit('submit')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.typeform-container {
    iframe {
        width: 100% !important;
        height: 100% !important;
    }
}
</style>
