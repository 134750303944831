<template>
    <div class="container" ref="container">
        <typeform
            src="https://form.typeform.com/to/od0zFvqX"
            @submit="onSubmit"
        />
    </div>
</template>

<script>
import Typeform from '@/components/typeform/Typeform'

export default {
    components: {
        Typeform,
    },
    methods: {
        onSubmit() {
            this.$router.back()
        },
    },
}
</script>

<style lang="scss" scoped>
#page .container {
    padding: 0;
}
.typeform-container {
    iframe {
        width: 100% !important;
        height: 100% !important;
    }
}
</style>
